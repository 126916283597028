import { MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Card from 'react-bootstrap/esm/Card';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { IListingItem } from '@/shared/interfaces';
import CorneredContainer from '@/components/cornered-container';

interface Props {
    disabled?: boolean;
    item: IListingItem;
}

function SingleItem({ disabled, item: { id, length, title, thumbnail } }: Props) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageLoadFailed, setImageLoadFailed] = useState(false);

    if (!id) {
        return null;
    }

    function handleClick(e: MouseEvent<HTMLAnchorElement>) {
        if (disabled) {
            e.preventDefault();
        }
    }

    return (
        <CorneredContainer removePadding className={`${!imageLoaded ? 'invisible' : ''} ${imageLoadFailed ? 'loading-failed' : ''}`}>
            <NavLink className="single-item" replace to={!disabled? `${location.pathname}?id=${id}`: ''} onClick={handleClick} >
                <Card className="text-white single-item__card">
                    <LazyLoadImage
                        alt={title}
                        className="card-img single-item__card-img"
                        src={thumbnail}
                        onError={() => {setImageLoadFailed(true); setImageLoaded(true);}}
                        onLoad={() => setImageLoaded(true)}/>
                    <Card.ImgOverlay>
                        <Card.Title>{title}</Card.Title>
                        <Card.Text>{length}</Card.Text>
                    </Card.ImgOverlay>
                </Card>
            </NavLink>
        </CorneredContainer>
    );
}

export default SingleItem;