import useTranslate from '@/hooks/useTranslate';
import IconNotification from '@/components/icons/icon-notification';

function NoContent() {
    const translate = useTranslate();

    return (
        <div className="no-content">
            <IconNotification size={128} />
            <h2>{translate.NO_CONTENT}</h2>
        </div>
    );
}

export default NoContent;