import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { login } from '@/shared/api';
import { ILogin, IState, IUser, IUserState, IMatch } from '@/shared/interfaces';
import { generateThunk } from '@/shared/utils';
import { USER_TYPE } from '@/shared/constants';

const initialState: IUserState = { };

export const loginThunk = createAsyncThunk('users/login', (payload: ILogin, thunkAPI) => generateThunk({ fn: login, payload, thunkAPI}));

export const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (user: IUserState) => {
            user = initialState;

            return user;
        },
        setProfile: (user: IUserState, { payload }: PayloadAction<IUser>) => {
            let ExclusiveInclusionMatches = '';

            if (payload.ExclusiveInclusionMatches != null && payload.ExclusiveInclusionMatches != '') {
                for (const matchString of payload.ExclusiveInclusionMatches.split(';')) {
                    const match: IMatch = JSON.parse( matchString );
                    ExclusiveInclusionMatches = ExclusiveInclusionMatches + match.Partita + ';';
                }
                ExclusiveInclusionMatches = ExclusiveInclusionMatches.slice(0, -1);
            }

            user.value = {
                ...payload,
                ExclusiveInclusionMatches: ExclusiveInclusionMatches
            };

            return user;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginThunk.pending, (user: IUserState) => {
            user.error = undefined;
            user.loading = true;
            user.loaded = false;
        });

        builder.addCase(loginThunk.fulfilled, (user: IUserState, { payload: token }) => {
            user.error = undefined;
            user.loading = false;
            user.loaded = true;
            user.value = { token };
        });

        builder.addCase(loginThunk.rejected, (user: IUserState, { error }) => {
            user.loading = false;
            user.loaded = false;
            user.error = error;
            user.value = undefined;
        });
    }
});

// actions
export const { logout, setProfile } = user.actions;

// reducer
export default user.reducer;

// selectors
export const selectUser = ({ user }: IState): IUserState => user;
export const selectUserIsLoading = createSelector(
    selectUser,
    (state: IUserState) => state?.loading
);
export const selectUserValue = ({ user }: IState): IUser => user.value || { };

export const selectFullName = createSelector(
    selectUserValue,
    (user: IUser) => user.Name
);

export const selectIsLoggedIn = createSelector(
    selectUser,
    (user: IUserState) => !!user.value
);

export const selectCanUserShowReport = createSelector(
    selectUser,
    (user: IUserState) => user.value?.Category === USER_TYPE.LEGA
);
