import { useDispatch, useSelector } from 'react-redux';
import CloseButton from 'react-bootstrap/CloseButton';
import Toast from 'react-bootstrap/Toast';

import { TOASTER_TIMEOUT_SECONDS } from '@/shared/constants';
import { removeToaster, selectToaster } from '@/state/core';

function Toaster() {
    const dispatch = useDispatch();
    const toaster = useSelector(selectToaster);

    function handleClose() {
        dispatch(removeToaster());
    }

    if (!toaster) {
        return null;
    }

    setTimeout(handleClose, TOASTER_TIMEOUT_SECONDS * 1000);

    const { date, level = 'secondary', message } = toaster;

    return (
        <Toast className={`bg-${level}`} show={!!message} onClose={handleClose} >
            <Toast.Body>
                <small>{date}</small> {message}
                <CloseButton onClick={handleClose} />
            </Toast.Body>
        </Toast>
    );
}

export default Toaster;
