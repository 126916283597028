import { ReactNode } from 'react';
import ReactMultiCarousel from 'react-multi-carousel';

interface IBreakpoints {
    mobile: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
}

interface Props {
    afterChange?: () => void;
    beforeChange?: () => void;
    children: ReactNode[];
}

const numberOfItems = { xl: 5, lg: 4, md: 3, mobile: 2 } as IBreakpoints;
const partialVisibilityGutter = { xl: 65, lg: 50, md: 35, mobile: 25 } as IBreakpoints;

function Carousel({ children, afterChange, beforeChange }: Props) {
    function buildBreakpointConfig (size: keyof IBreakpoints) {
        return {
            items: numberOfItems[size],
            partialVisibilityGutter: children.length > numberOfItems[size] ? partialVisibilityGutter[size] : 0,
            slidesToSlide: numberOfItems[size]
        };
    }

    const carouselResponsive = {
        xl: { breakpoint: { max: 3000, min: 1920 }, ...buildBreakpointConfig('xl') },
        lg: { breakpoint: { max: 1920, min: 1024 }, ...buildBreakpointConfig('lg') },
        md: { breakpoint: { max: 1024, min: 464 }, ...buildBreakpointConfig('md') },
        mobile: { breakpoint: { max: 464, min: 0 }, ...buildBreakpointConfig('mobile') }
    };

    return (
        <ReactMultiCarousel
            afterChange={afterChange}
            beforeChange={beforeChange}
            arrows
            itemClass="image-item"
            partialVisible
            renderDotsOutside={true}
            responsive={carouselResponsive}
        >
            {children}
        </ReactMultiCarousel>
    );
}

export default Carousel;