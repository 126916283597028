function IconSearch() {
    return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 24 24">
        <path className="st0" fill="#ffffff" d="M11,2c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9S6,2,11,2z M11,18c3.9,0,7-3.1,7-7s-3.1-7-7-7s-7,3.1-7,7S7.1,18,11,18z
            M19.5,18.1l2.8,2.8l-1.4,1.4l-2.8-2.8L19.5,18.1z"/>
        </svg>
    );
}

export default IconSearch;