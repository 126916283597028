import { memo } from 'react';
import useTranslate from '@/hooks/useTranslate';

function Footer() {
    const translate = useTranslate();
    const { CONDITIONS } = translate;

    return (
        <footer className="footer">
            <p>
                <a className='custom-font-weight-medium' target="_blank" href={CONDITIONS.WEBSITE_PRIVACY_POLICY_LINK} rel="noreferrer">{CONDITIONS.WEBSITE_PRIVACY_POLICY}</a>
                <a className='custom-font-weight-medium' target="_blank" href={CONDITIONS.COOKIE_POLICY_LINK} rel="noreferrer">{CONDITIONS.COOKIE_POLICY}</a>
            </p>
        </footer>
    );
}

export default memo(Footer);