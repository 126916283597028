import { LOCALSTORAGE_KEYS } from '@/shared/constants';

function useLocalStorage() {
  let storedToken = getToken();

  function getToken() {
    return localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN);
  }

  function setToken(token: string) {
    storedToken = token;
    localStorage.setItem(LOCALSTORAGE_KEYS.TOKEN, token);
  }

  function removeToken() {
    localStorage.removeItem(LOCALSTORAGE_KEYS.TOKEN);
  }

  return { storedToken, getToken, setToken, removeToken };
}

export default useLocalStorage;