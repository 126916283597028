import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Detail from '@/components/detail';
import ListingGroup from '@/components/listing-group';
import Loading from '@/components/loading';
import NoContent from '@/components/no-content';
import useChampionship from '@/hooks/useChampionship';
import useDetailId from '@/hooks/useDetailId';
import useTranslate from '@/hooks/useTranslate';
import { IListingItem, IListingGroup, IListingCategory } from '@/shared/interfaces';
import { setToaster } from '@/state/core';
import { getListingThunk, selectListing } from '@/state/listing';
import { Collapse, ListGroup } from 'react-bootstrap';
import { selectSearch } from '@/state/search';
import { selectMenu } from '@/state/menu';
import { SlideshowList } from '../listing-slideshow/slideshow';
import ListingSlideshow from '../listing-slideshow';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Listing() {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const translate = useTranslate();
    const listing = useSelector(selectListing); // listing state with full listing for selected competition
    const searchText = useSelector(selectSearch);
    const showMenu = useSelector(selectMenu);
    const [listingCategories, setListingCategories] = useState<IListingCategory[]>(); // categories
    const [selectedCategory, setSelectedCategory] = useState<IListingCategory>();

    // Set championship
    useChampionship();
    const id = useDetailId();

    /*** Event handling */
    useEffect(function() {
        dispatch(getListingThunk());
    }, [pathname]);

    // listing initialization: get categories and render only the menu
    useEffect(function() {
        if (listing.error) {
            dispatch(setToaster({ message: translate.GENERIC_ERROR}));
            return;
        }
        if (listing.value) {
            const initialListing = filterEmptyListingContent(listing.value);
            setCategoriesFromListing(initialListing);
            setSelectedCategory(undefined);
            document.body.classList.remove('category-selected');
        }
    }, [listing]);

    // Search
    useEffect(function() {
        console.debug('Search text', searchText.value?.text);
        if (selectedCategory) {
            applyFilter(searchText.value?.text || '');
        }
    }, [searchText]);

    /*** Methods */
    function filterEmptyListingContent(content: IListingGroup[]): IListingGroup[] {
        return content?.filter(({ items: { length }}) => length);
    }

    function setCategoriesFromListing(contentListing: IListingGroup[], selectedCategoryId: number | undefined = undefined) {
        const categories: IListingCategory[] = contentListing
            .map((listing, i) => { return { id: i, name: listing.name, size: listing.items.length, items: [...listing.items] }; });
        if (categories) {
            setListingCategories([...categories]);
            if (selectedCategoryId !== undefined) {
                setSelectedCategory(categories.find(category => category.id === selectedCategoryId));
            }
        }
    }

    function selectCategory(index: number | undefined = undefined) {
        console.debug('Category selected', index, listingCategories?.length);
        if (listingCategories) {
            let categoryIndex: number | undefined;
            if (index === undefined) { // TODO: refactor
                categoryIndex = listingCategories ? listingCategories[0].id : undefined;
            } else {
                categoryIndex = index;
            }
            if (categoryIndex !== undefined) {
                const categoryListingSelected = listingCategories.find(category => category.id === categoryIndex);
                console.debug('Category', categoryListingSelected);
                setSelectedCategory(categoryListingSelected);
                document.body.classList.add('category-selected');
            }
        }
    }

    function applyFilter(searchText: string) { // TODO: filter only on category selected?
        if (listing.value) {
            const initialListing = filterEmptyListingContent(listing.value);
            if (!searchText.trim()) {
                setCategoriesFromListing(initialListing, selectedCategory?.id);
                return;
            }
            const filterListingBySearchText = ({ title }: IListingItem) => title?.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1;
            const filteredList: IListingGroup[] = initialListing?.map(({ name, items }) => ({name, items: items.filter(filterListingBySearchText) }));
            setCategoriesFromListing(filteredList, selectedCategory?.id);
        }
    }

    function renderCategory(group: IListingCategory, index: number) {
        return <ListingGroup group={group} key={`${group.name}${index}`} />;
    }

    function renderContent(value: string) {
        return <LazyLoadImage src={value} key={value} className='listing-slideshow-item' />;
    }

    return (
        <div className="d-flex flex-column listing-container">
            <div className="listing-header">
                <ListingSlideshow>
                    {SlideshowList.map(renderContent)}
                </ListingSlideshow>

                {listing.loaded && listingCategories && listingCategories.length > 0 && ( // null is used to render nothing (see )
                    <Collapse
                        in={showMenu.value?.isOpen}
                        appear
                        >
                        <ListGroup as="ul" className="listing-category-menu custom-font-weight-light" id="listing-menu">
                            {listingCategories.map((category) => {
                                return <ListGroup.Item as="li" action eventKey={category.id} onClick={() => { selectCategory(category.id); }} key={category.id} className="custom-font-weight-medium"><span className="marker">&nbsp;</span> {translate.GROUP_TITLES[category.name] ?? category.name}</ListGroup.Item>;
                            })}
                        </ListGroup>
                    </Collapse>
                )}
            </div>
            <div className="my-2 listing-content">
                <Loading show={listing.loading} />
                {listing.loaded && listingCategories && !listingCategories.length && <NoContent /> }
                {!listing.loading && selectedCategory && [selectedCategory].map(renderCategory)}
                { id && <Detail id={id} /> }
            </div>
        </div>
    );
}

export default Listing;