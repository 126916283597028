import { useState } from 'react';
import Badge from 'react-bootstrap/esm/Badge';

import Carousel from '@/components/carousel';
import SingleItem from '@/components/single-item';
import useTranslate from '@/hooks/useTranslate';
import { IListingCategory, IListingGroup, IListingItem } from '@/shared/interfaces';

interface Props {
    group: IListingGroup | IListingCategory;
}

function ListingGroup({ group: { items, name } }: Props) {
    const [disabled, setDisabled] = useState(false);
    const translate = useTranslate();
    const categoryName = translate.GROUP_TITLES[name] ?? name;

    function renderContent(content: IListingItem) {
        return <SingleItem disabled={disabled} item={content} key={content.id} />;
    }

    function beforeChange() { setDisabled(true); }
    function afterChange() { setDisabled(false); }

    return (
        <div className="d-flex flex-column listing-group">
            <div className="listing-group__name px-3 custom-font-weight-bold">
                {categoryName.toUpperCase()}
                <Badge className='custom-font-weight-bold'>
                    {items.length}
                </Badge>
            </div>
            <div className="listing-group__carousel pl-5">
                <Carousel afterChange={afterChange} beforeChange={beforeChange}>
                    {items.filter(Boolean).map(renderContent)}
                </Carousel>
            </div>
        </div>
    );
}

export default ListingGroup;