import SlideshowImage01 from '../../images/slideshow/slideshow-01.jpg';
import SlideshowImage02 from '../../images/slideshow/slideshow-02.jpg';
import SlideshowImage03 from '../../images/slideshow/slideshow-03.jpg';
import SlideshowImage04 from '../../images/slideshow/slideshow-04.jpg';
import SlideshowImage05 from '../../images/slideshow/slideshow-05.jpg';
import SlideshowImage06 from '../../images/slideshow/slideshow-06.jpg';
import SlideshowImage07 from '../../images/slideshow/slideshow-07.jpg';
import SlideshowImage08 from '../../images/slideshow/slideshow-08.jpg';
import SlideshowImage09 from '../../images/slideshow/slideshow-09.jpg';
import SlideshowImage10 from '../../images/slideshow/slideshow-10.jpg';
import SlideshowImage11 from '../../images/slideshow/slideshow-11.jpg';
import SlideshowImage12 from '../../images/slideshow/slideshow-12.jpg';
import SlideshowImage13 from '../../images/slideshow/slideshow-13.jpg';
import SlideshowImage14 from '../../images/slideshow/slideshow-14.jpg';
import SlideshowImage15 from '../../images/slideshow/slideshow-15.jpg';
import SlideshowImage16 from '../../images/slideshow/slideshow-16.jpg';
import SlideshowImage17 from '../../images/slideshow/slideshow-17.jpg';
import SlideshowImage18 from '../../images/slideshow/slideshow-18.jpg';
import SlideshowImage19 from '../../images/slideshow/slideshow-19.jpg';
import SlideshowImage20 from '../../images/slideshow/slideshow-20.jpg';

export const SlideshowList = [
    SlideshowImage01,
    SlideshowImage02,
    SlideshowImage03,
    SlideshowImage04,
    SlideshowImage05,
    SlideshowImage06,
    SlideshowImage07,
    SlideshowImage08,
    SlideshowImage09,
    SlideshowImage10,
    SlideshowImage11,
    SlideshowImage12,
    SlideshowImage13,
    SlideshowImage14,
    SlideshowImage15,
    SlideshowImage16,
    SlideshowImage17,
    SlideshowImage18,
    SlideshowImage19,
    SlideshowImage20
];
