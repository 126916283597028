import { Switch, Redirect } from 'react-router-dom';

import ContentSelect from '../content-select';
import Listing from '@/components/listing';
import Login from '@/components/login';
import Logout from '@/components/logout';
import Profile from '@/components/profile';
import Report from '@/components/report';
import RestrictedRoute from '@/components/route/RestrictedRoute';
import { ROUTES, THEMES } from '@/shared/constants';

function Routes() {
    return (
        <Switch>
            <RestrictedRoute path={ROUTES.CONTENT_SELECT} component={ContentSelect} theme={THEMES.CONTENT_SELECT}/>
            <RestrictedRoute path={ROUTES.LISTING_SERIE_A} component={Listing} theme={THEMES.LISTING_SERIE_A}/>
            <RestrictedRoute path={ROUTES.LISTING_COPPA_ITALIA} component={Listing} theme={THEMES.LISTING_COPPA_ITALIA} />
            <RestrictedRoute path={ROUTES.LISTING_SUPER_COPPA} component={Listing} theme={THEMES.LISTING_SUPER_COPPA} />
            <RestrictedRoute path={ROUTES.PROFILE} component={Profile} theme={THEMES.PROFILE} />
            <RestrictedRoute path={ROUTES.REPORT} component={Report} theme={THEMES.REPORT} />

            <RestrictedRoute restricted={false} path={ROUTES.LOGIN} component={Login} theme={THEMES.LOGIN} />
            <RestrictedRoute restricted={false} path={ROUTES.LOGOUT} component={Logout} theme={THEMES.LOGOUT} />

            <Redirect exact from={ROUTES.HOME} to={ROUTES.CONTENT_SELECT} />
        </Switch>
    );
}

export default Routes;