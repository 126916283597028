import Button, { ButtonProps } from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/esm/Spinner';

interface Props extends ButtonProps {
    children?: string;
    icon?: React.ReactNode;
    loading?: boolean;
    loadingText?: string;
    text?: string;
}

function LoadingButton({ children, disabled, icon, loading = false, loadingText, text, variant = 'secondary', ...otherProps}: Props) {
    const renderText = ((loading && loadingText) || text || children);

    return (
        <Button disabled={disabled || loading} variant={variant} {...otherProps}>
            {renderText}
            {icon && !loading && <span>{icon}</span>}
            {loading && <Spinner animation="border" size="sm" />}
        </Button>
    );
}

export default LoadingButton;