import Spinner from 'react-bootstrap/esm/Spinner';

interface Props {
    show?: boolean;
}

function Loading({ show = true } : Props) {
    if (!show) {
        return null;
    }

    return <Spinner className="loading" animation="border" />;
}

export default Loading;