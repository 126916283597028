import en from '@/i18n/en';
import it from '@/i18n/it';

export const Languages = {
    en: 'english',
    it: 'italiano'
};

export type dicTerms = keyof typeof en;
export type languagesKeys = keyof typeof Languages;
export type ILanguages = Record<languagesKeys, Record<dicTerms, any>>;

export const dic: ILanguages = {
    en,
    it
};
