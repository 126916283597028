import { BrowserRouter } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';

import Conditions from '@/components/conditions';
import Footer from '@/components/footer';
import Navbar from '@/components/navbar';
import Routes from '@/components/route/Routes';
import Toaster from '@/components/toaster';
import useLogin from '@/hooks/useLogin';

function App() {
    useLogin();

    return (
        <div className="App">
            <BrowserRouter>
                <Navbar />
                <Container fluid className="page">
                    <Routes />
                </Container>
            </BrowserRouter>
            <Toaster />
            <Conditions />
            <Footer />
        </div>
    );
}

export default App;
