import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CHAMPIONSHIPS, ChampionshipsValues } from '@/shared/constants';
import { ICore, ICoreState, IState, IToaster } from '@/shared/interfaces';

const initialState: ICoreState = { value : { championship: CHAMPIONSHIPS.SERIE_A } };

export const core = createSlice({
    name: 'core',
    initialState,
    reducers: {
        setChampionship: (coreState: ICoreState, { payload: championship = CHAMPIONSHIPS.SERIE_A }: PayloadAction<ChampionshipsValues>) => {
            coreState.value = {
                ...coreState.value,
                championship
            };

            return coreState;
        },
        setToaster: function(coreState: ICoreState = initialState, { payload }: PayloadAction<IToaster>) {
            coreState.value = {
                ...coreState.value,
                toaster : { ...payload, date: new Date().toLocaleTimeString() }
            };

            return coreState;
        },
        removeToaster: (coreState: ICoreState) => {
            coreState.value = {
                ...coreState.value,
                toaster : initialState.value?.toaster
            };

            return coreState;
        }
    }
});

export const { setChampionship, setToaster, removeToaster } = core.actions;

export default core.reducer;

const selectCore = (state: IState) => state.core;
const selectCoreValue = (state: IState) => state.core.value ?? { };

export const selectCoreIsLoading = createSelector(
    selectCore,
    (state: ICoreState) => state?.loading
);

export const selectToaster = createSelector(
    selectCoreValue,
    (coreState: ICore) => coreState?.toaster
);

export const selectActualChampionship = createSelector(
    selectCore,
    (state: ICoreState) => state?.value?.championship
);