import { ReactNode } from 'react';
import ReactMultiCarousel from 'react-multi-carousel';

interface Props {
    children: ReactNode[];
}

function ListingSlideshow({ children }: Props) {
    const carouselResponsive = {
        desktop: {
            breakpoint: { max: 3000, min: 0 },
            items: 1
        }
    };

    return (
        <ReactMultiCarousel
            itemClass="image-item"
            containerClass='listing-slideshow'
            arrows={false}
            autoPlay
            infinite
            autoPlaySpeed={3000}
            pauseOnHover={false}
            draggable={false}
            responsive={carouselResponsive}
        >
            {children}
        </ReactMultiCarousel>
    );
}

export default ListingSlideshow;