/* eslint-disable max-len */
function IconPerson() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 24 24" >
            <path className="st0" fill="#ffffff" d="M20,22H4v-2c0-2.8,2.2-5,5-5h6c2.8,0,5,2.2,5,5V22z M12,13c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6c3.3,0,6,2.7,6,6
                C18,10.3,15.3,13,12,13z"/>
        </svg>
    );
}

export default IconPerson;