/* eslint-disable max-len */
interface Props {
    className?: string
}

function IconBack({ className }: Props) {
    return (
        <div className={className}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 24 24" >
                <path className="st0" fill="#ffffff" d="M10.8,12l4.9,4.9l-1.4,1.4L8,12l6.4-6.4l1.4,1.4L10.8,12z"/>
            </svg>
        </div>
    );
}

export default IconBack;