import exportFromJSON from 'export-from-json';
import { ChangeEvent, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loading from '@/components/loading';
import LoadingButton from '@/components/loading-button';
import useTranslate from '@/hooks/useTranslate';
import { downloadStatistics } from '@/shared/api';
import { ROUTES } from '@/shared/constants';
import { IReportItem } from '@/shared/interfaces';
import { selectCanUserShowReport, selectUser } from '@/state/user';

function Report() {
    const history = useHistory();
    const translate = useTranslate();

    const [reportItems, setReportItems] = useState<IReportItem[]>();
    const [dateFrom, setDateFrom] = useState<string>('');
    const [dateTo, setDateTo] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const canUserShowReport = useSelector(selectCanUserShowReport);
    const user = useSelector(selectUser);

    useEffect(function setInitialDates() {
        const today = new Date().toISOString().substr(0, 10);
        const oneMonthAgo = new Date();

        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        oneMonthAgo.setHours(0, 0, 0, 0);

        setDateFrom(oneMonthAgo.toISOString().substr(0, 10));
        setDateTo(today);
    }, []);

    useEffect(function loadReport() {
        if (user.value && !canUserShowReport) {
            history.push(ROUTES.LOGIN);
        }
    }, [canUserShowReport, user]);

    if (!user.value) {
        return <Loading />;
    }

    function downloadXls() {
        if (!reportItems) {
            return;
        }

        const fileName = `report_${dateFrom}_${dateTo}`;

        exportFromJSON({ data: reportItems, fileName, exportType: exportFromJSON.types.xls });
    }

    function dateFromOnChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        setDateFrom(value);
    }

    function dateToOnChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
        setDateTo(value);
    }

    async function getReport () {
        if (!dateFrom || !dateTo) {
            return;
        }

        setLoading(true);
        setReportItems(await downloadStatistics(dateFrom, dateTo));
        setLoading(false);
    }

    const exportButton = (
        <LoadingButton
            className="btn-block-xs-only"
            disabled={!reportItems?.length}
            onClick={downloadXls}
            text={translate.EXPORT_XLS}
            type="button"
            variant="primary"
        />
    );

    const dateFromInput = (
        <>
            <Form.Label>{translate.FROM_DATE}</Form.Label>
            <Form.Group>
                <Form.Control
                    disabled={loading}
                    name="fromDate"
                    onChange={dateFromOnChange}
                    type="date"
                    value={dateFrom}
                />
            </Form.Group>
        </>
    );

    const dateToInput = (
        <>
            <Form.Label>{translate.TO_DATE}</Form.Label>
            <Form.Group>
                <Form.Control
                    disabled={loading}
                    name="toDate"
                    onChange={dateToOnChange}
                    type="date"
                    value={dateTo}
                />
            </Form.Group>
        </>
    );

    const getReportButton = (
        <LoadingButton
            className="btn-block-xs-only"
            disabled={loading}
            loading={loading}
            onClick={getReport}
            text={translate.GET_REPORT}
            type="button"
        />
    );

    const reportTable = reportItems && (
        <Table id="tableReport" variant="secondary" hover responsive>
            <thead>
                <tr>
                    <th>{translate.REPORT_COLUMNS.COUNTER}</th>
                    <th>{translate.REPORT_COLUMNS.FILE_NAME}</th>
                    <th>{translate.REPORT_COLUMNS.PROD_STD}</th>
                    <th>{translate.REPORT_COLUMNS.PROD_VALUE}</th>
                    <th>{translate.REPORT_COLUMNS.USER_NAME}</th>
                    <th>{translate.REPORT_COLUMNS.USER_ID}</th>
                </tr>
            </thead>
            <tbody>
                {reportItems.map(({ ProdStd, counter, fileName, userId, userName, ProdValue }) => (
                    <tr key={`${userId}-${fileName}`}>
                        <td>{counter}</td>
                        <td>{fileName}</td>
                        <td>{ProdStd}</td>
                        <td>{ProdValue}</td>
                        <td>{userName}</td>
                        <td>{userId}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    return (
        <div className="report m-3">

            <div className="row">
                <div className="col-md-6">
                    {dateFromInput}
                </div>
                <div className="col-md-6">
                    {dateToInput}
                </div>
            </div>

            <div className="mb-5">{getReportButton} {exportButton}</div>

            <div className="row">
                <div className="col-md-12">
                    <div className="report__table">
                        {!loading && reportTable}
                        <Loading show={loading} />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Report;