import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ChampionshipsValues } from '@/shared/constants';
import { setChampionship } from '@/state/core';

function useChampionship() {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [, , championship] = pathname.split('/');

    dispatch(setChampionship(championship as ChampionshipsValues));
}

export default useChampionship;
