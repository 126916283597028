import { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import ColoredCorner, { CornerPosition } from '../colored-corner';

interface Props {
    children: ReactNode;
    className?: string;
    removePadding?: boolean;
    topLeft?: boolean;
    topRight?: boolean;
    bottomLeft?: boolean;
    bottomRight?: boolean;
}

function CorneredContainer({ children, className, removePadding = false, topLeft = false, topRight = false, bottomLeft = false, bottomRight = false }: Props) {
    return (
        <Container className={`cornered-container ${!!className ? className : ''} ${removePadding ? 'remove-padding' : ''}`}>
            {children}
            {topLeft ? <ColoredCorner position={CornerPosition.TopLeft} /> : ''}
            {topRight ? <ColoredCorner position={CornerPosition.TopRight} /> : ''}
            {bottomLeft ? <ColoredCorner position={CornerPosition.BottomLeft} /> : ''}
            {bottomRight ? <ColoredCorner position={CornerPosition.BottomRight} /> : ''}
        </Container>
    );
}

export default CorneredContainer;