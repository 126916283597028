/* eslint-disable max-len */
function IconDownload() {
    return (
        <svg version="1.1" className="bi" width={16} height={16} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 24 24">
            <path d="M3,19h18v2H3V19z M13,9h4l-5,8L7,9h4V1h2V9z"/>
        </svg>
    );
}

export default IconDownload;