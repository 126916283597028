import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/esm/Image';
import { ROUTES } from '@/shared/constants';
import useTranslate from '@/hooks/useTranslate';

import SerieALogo from '@/images/logo-192.jpg';
import CoppaItaliaLogo from '@/images/italian-cup-192.jpg';
import SuperCoppaLogo from '@/images/supercup-192.jpg';

function ContentSelect() {
    const translate = useTranslate();

    return (
        <Container className="content-select">
            <Row>
                <Col className="content-select-title custom-font-weight-medium">
                    {translate.SELECT_CONTENT}
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center content-select-nav">
                <Col className="d-flex flex-column container">
                    <a href={ROUTES.LISTING_SERIE_A}>
                        <div className="logo seriea">
                            <Image src={SerieALogo} />
                        </div>
                        <div className="caption seriea custom-font-weight-bold">
                            {translate.SERIEA}
                        </div>
                    </a>
                </Col>
                <Col className="d-flex flex-column container">
                    <a href={ROUTES.LISTING_COPPA_ITALIA}>
                        <div className="logo cita">
                            <Image src={CoppaItaliaLogo} />
                        </div>
                        <div className="text-center caption cita custom-font-weight-bold">
                            {translate.CPITA}
                        </div>
                    </a>
                </Col>
                <Col className="d-flex flex-column container">
                    <a href={ROUTES.LISTING_SUPER_COPPA}>
                        <div className="logo scita">
                            <Image src={SuperCoppaLogo} />
                        </div>
                        <div className="text-center caption scita custom-font-weight-bold">
                            {translate.SCITA}
                        </div>
                    </a>
                </Col>
            </Row>
        </Container>
    );

}

export default ContentSelect;