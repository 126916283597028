import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { dic, dicTerms, languagesKeys, Languages } from '@/i18n/dic';

function useTranslate() {
    const [cookies, setCookie] = useCookies(['lang']);
    const lang = cookies.lang as languagesKeys;
    const [currentDic, setCurrentDic] = useState<Record<dicTerms, any>>();

    useEffect(function() {
        if (!lang) {
            setCookie('lang', Languages.en, { path: '' });
            return;
        }

        try {
            setCurrentDic(dic[lang] ?? dic.en);
        }
        catch (err) {
            // empty on purpose.
        }

    }, [lang]);

    return currentDic || dic.en;
}

export default useTranslate;