import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { parseJwt } from '@/shared/utils';
import { setProfile } from '@/state/user';
import useLocalStorage from '@/hooks/useLocalStorage';

function useLogin() {
    const dispatch = useDispatch();
    const storage = useLocalStorage();

    useEffect(function() {
        if (!storage.storedToken) {
            return;
        }

        try {
            const user = parseJwt(storage.storedToken);
            dispatch(setProfile(user));
        }
        catch (err) {
            // empty on purpose.
        }

    }, [storage.getToken()]);
}

export default useLogin;