/* eslint-disable max-len */
function IconMenu() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 240 240">
            <path className="st0" fill="#ffffff" d="M30,40h180v20H30V40z M30,110h180v20H30V110z M30,180h180v20H30V180z"/>
        </svg>
    );
}

export default IconMenu;