interface IEnvironments {
    LOCAL: string;
    TEST: string;
    STAGING: string;
    PROD: string;
}

const version = 1;
const DEFAULT_ENVIRONMENT = 'PROD';

const environments: IEnvironments = {
    LOCAL: 'https://localhost:5001/',
    TEST: 'TODO',
    STAGING: 'https://mpa-web-api-staging.azurewebsites.net/',
    PROD: 'https://mpa-web-api.azurewebsites.net/'
};

export type ValidPaths = 'auth/login' | 'calendar' | 'GetDownloadLink' | 'GetDownloadStatistics' | 'PurgeCache';
export type ValidMethods = 'GET' | 'POST';

export interface IFetch {
    body?: Record<string, any>;
    method?: ValidMethods;
    path: ValidPaths;
    queryString?: Record<string, any>;
    useToken?: boolean;
}

const currentEnvironment = (process.env.REACT_APP_API_ENV || DEFAULT_ENVIRONMENT) as keyof IEnvironments;

export const baseApiUrl = `${(environments[(currentEnvironment)])}api/v${version}/`;
