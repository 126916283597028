import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Image from 'react-bootstrap/esm/Image';
import Navbar from 'react-bootstrap/esm/Navbar';
import Nav from 'react-bootstrap/esm/Nav';

import IconPerson from '@/components/icons/icon-person';
import useTranslate from '@/hooks/useTranslate';
import { CHAMPIONSHIPS, ROUTES } from '@/shared/constants';
import { selectCanUserShowReport } from '@/state/user';

import SerieA from '@/images/logo-192.jpg';
import ItalianCup from '@/images/italian-cup-192.jpg';
import SuperCup from '@/images/supercup-192.jpg';
import IconSearch from '../icons/icon-search';
import IconReload from '../icons/icon-reload';
import { Button, Container, Form, FormControl, NavDropdown } from 'react-bootstrap';
import IconArrowBack from '../icons/icon-arrow-back';
import React, { useEffect, useRef, useState } from 'react';
import { getListingThunk } from '@/state/listing';
import { selectActualChampionship } from '@/state/core';
import { startSearch } from '@/state/search';
import IconMenu from '../icons/icon-menu';
import { setMenuOpen } from '@/state/menu';
import IconUserManual from '../icons/icon-user-manual';
import useLocalStorage from '@/hooks/useLocalStorage';

function Menu() {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const storage = useLocalStorage();
    const location = useLocation();
    const canUserShowReport = useSelector(selectCanUserShowReport);
    const selectedChampionship = useSelector(selectActualChampionship);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const searchInput = useRef<HTMLInputElement>(null);
    let championshipDescription: string | undefined;
    let championshipIcon: string | undefined;
    let championshipUrl: string | undefined;

    useEffect(() => {
        dispatch(setMenuOpen({ isOpen: menuIsOpen }));
    });

    switch (selectedChampionship) {
        case CHAMPIONSHIPS.SERIE_A:
            championshipDescription = translate.SERIEA;
            championshipIcon = SerieA;
            championshipUrl = ROUTES.LISTING_SERIE_A;
            break;
        case CHAMPIONSHIPS.COPPA_ITALIA:
            championshipDescription = translate.CPITA;
            championshipIcon = ItalianCup;
            championshipUrl = ROUTES.LISTING_COPPA_ITALIA;
            break;
        case CHAMPIONSHIPS.SUPER_COPPA:
            championshipDescription = translate.SCITA;
            championshipIcon = SuperCup;
            championshipUrl = ROUTES.LISTING_SUPER_COPPA;
            break;
    }

    if (!storage.storedToken || location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.CONTENT_SELECT) {
        return null;
    }

    function reloadContent() {
        dispatch(getListingThunk());
    }

    function search() {
        dispatch(startSearch({ text: searchInput.current?.value}));
    }

    function toggleMenu() {
        setMenuIsOpen(!menuIsOpen);
        dispatch(setMenuOpen({ isOpen: !menuIsOpen }));
    }

    function updatePlaceholder(event: React.FocusEvent<HTMLInputElement>, placeholderText: string) {
        event.target.placeholder = placeholderText;
    }

    return (
        <Navbar variant="dark" sticky="top">
            <Nav className="pl-2 pr-4">
                <a href={ROUTES.CONTENT_SELECT}><IconArrowBack className="back-button" /></a>
            </Nav>
            <Nav className="navbar-brand pr-4">
                <a href={championshipUrl}><Image src={championshipIcon} /></a>
            </Nav>
            <Nav className="navbar-menu-toggle">
                <Button
                    onClick={() => toggleMenu()}
                    aria-controls="listing-menu"
                    aria-expanded={menuIsOpen}
                >
                    <IconMenu />
                </Button>
            </Nav>
            <Container className="container-full justify-content-end mr-5 navbar-right-menu">
                <Nav className='h-100'>
                    <Nav.Link className="mx-2 align-self-center" href={translate.USER_MANUAL.LINK} target='_blank' rel="noreferrer"><IconUserManual /></Nav.Link>
                    <Nav.Link className="mx-2 align-self-center" onClick={reloadContent}><IconReload /></Nav.Link>

                    <Nav.Item  className="mx-2 align-self-center icon-menu">
                        <NavDropdown title={<span><IconSearch /></span>} id="search-menu" onClick={(e) => e.preventDefault()} renderMenuOnMount={true}>
                            <NavDropdown.ItemText className="dropdown-title">{championshipDescription}</NavDropdown.ItemText>

                            <NavDropdown.ItemText className='py-2'>
                                <Form inline onSubmit={(e) => e.preventDefault()}>
                                    <FormControl
                                        type="text"
                                        placeholder={translate.SEARCH_TEXT}
                                        className=""
                                        ref={searchInput}
                                        id="search-text"
                                        onChange={search}
                                        onFocus={(event: React.FocusEvent<HTMLInputElement>) => updatePlaceholder(event, translate.SEARCH_TEXT_FOCUS)}
                                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => updatePlaceholder(event, translate.SEARCH_TEXT)} />
                                </Form>
                            </NavDropdown.ItemText>
                        </NavDropdown>
                    </Nav.Item >

                    <Nav.Item  className="mx-2 align-self-center icon-menu">
                        <NavDropdown title={<span><IconPerson /></span>} id="user-profile-menu" rootCloseEvent='click'>
                            <NavDropdown.Item className="dropdown-title custom-font-weight-medium">{championshipDescription}</NavDropdown.Item>
                            <NavDropdown.Item className='custom-font-weight-light' href={ROUTES.PROFILE}>{translate.USERINFO}</NavDropdown.Item>
                            { canUserShowReport &&
                                <NavDropdown.Item className='custom-font-weight-light' href={ROUTES.REPORT}>{translate.REPORT}</NavDropdown.Item>
                            }
                            <NavDropdown.Item className='custom-font-weight-light' href={ROUTES.LOGOUT}>{translate.LOGOUT}</NavDropdown.Item>
                        </NavDropdown>
                    </Nav.Item >
                </Nav>
            </Container>
        </Navbar>
    );
}

export default Menu;