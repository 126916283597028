import { useCookies } from 'react-cookie';
import { useState } from 'react';
import { capitalizeFirstLetter } from '@/shared/utils';
import { languagesKeys, Languages } from '@/i18n/dic';
import IconDown from '@/components/icons/icon-arrow-down';

function SelectLanguage() {
    const [{ lang }, setCookie] = useCookies(['lang']);
    const [dropdownOpened, setDropdownOpened] = useState(false);

    function updateLanguageCookie(language: string) {
        setDropdownOpened(!dropdownOpened);

        if (language != lang) {
            setCookie('lang', language, { path: '' });
        }
    }

    function checkCookieLangValue(languageKey: languagesKeys, language: string, cookie: any) {
        if (cookie == undefined || (!Object.keys(Languages).includes(cookie) && !Object.values(Languages).includes(cookie))) {
            return language == Languages.en;
        }

        return cookie == languageKey || cookie == language;
    }

    return (
        <div className={`select-language ${dropdownOpened ? 'dropdown-opened' : ''}`}>
            {Object.keys(Languages).map((language) => (
                <div key={language}
                  className={`px-3 py-2 custom-font-weight-medium ${checkCookieLangValue(language as languagesKeys, Languages[language as languagesKeys], lang) ? 'active' : 'not-active'}`}
                  onClick={() => updateLanguageCookie(language)}>
                    {capitalizeFirstLetter(Languages[language as languagesKeys])}
                    {checkCookieLangValue(language as languagesKeys, Languages[language as languagesKeys], lang) ? <span className="arrow-icon"><IconDown /></span> : ''}
                </div>
            ))}
        </div>
    );
}

export default SelectLanguage;