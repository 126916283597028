import { ChangeEvent, useState } from 'react';
import { useCookies } from 'react-cookie';
import Modal from 'react-bootstrap/esm/Modal';
import Form from 'react-bootstrap/Form';

import IconCancel from '@/components/icons/icon-cancel';
import IconCheck from '@/components/icons/icon-check';
import LoadingButton from '@/components/loading-button';
import SelectLanguage from '@/components/select-language';
import useTranslate from '@/hooks/useTranslate';
import { COOKIES } from '@/shared/constants';
import CorneredContainer from '../cornered-container';

function Conditions() {
    const translate = useTranslate();
    const { HAS_SEEN_DISCLAIMER, HAS_SEEN_PRIVACY_INFORMATION } = COOKIES;
    const [{ hasSeenDisclaimer, hasSeenPrivacyInformation }, setCookie] = useCookies([HAS_SEEN_DISCLAIMER, HAS_SEEN_PRIVACY_INFORMATION]);

    const areConditionsAccepted = hasSeenDisclaimer && hasSeenPrivacyInformation;

    const [modalIsOpen, setModalIsOpen] = useState(!areConditionsAccepted);

    function handleClose() {
        setModalIsOpen(!areConditionsAccepted);
    }

    function acceptDisclaimer({ target: { checked } }: ChangeEvent<HTMLInputElement>) {
        setCookie(HAS_SEEN_DISCLAIMER, checked ? '1' : '', { path: '' });
    }

    function acceptPrivacyInformation() {
        setCookie(HAS_SEEN_PRIVACY_INFORMATION, 1, { path: '' });
    }

    return (
        <Modal className="conditions" show={modalIsOpen} size="lg" backdrop="static" centered>
            <CorneredContainer topLeft bottomRight removePadding>
                <Modal.Header>
                    <Modal.Title className="w-100 pt-3">
                        <div className="w-100 py-2 px-3 title custom-font-weight-light">{translate.CONDITIONS.TITLE}</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <hr className="border-light" />

                    <div className="p-3">
                        <label className='custom-font-weight-light'>{translate.SELECT_LANGUAGE}</label><br/>
                        <SelectLanguage />
                    </div>

                    <hr className="border-light mb-2" />

                    <div className="px-3 py-1">
                        <a
                            className="cursor-pointer custom-font-weight-light"
                            href={translate.CONDITIONS.PRIVACY_INFORMATION_LINK}
                            onClick={acceptPrivacyInformation}
                            rel="noreferrer"
                            target="_blank">
                            {hasSeenPrivacyInformation ? <IconCheck /> : <IconCancel />}
                            <span className="ml-3">{translate.CONDITIONS.PRIVACY_INFORMATION}</span>
                        </a>
                    </div>

                    <hr className="border-light mt-2" />

                    <div className="p-3 custom-font-weight-light">
                        <Form.Group controlId="disclaimer">
                            <Form.Check
                                checked={!!hasSeenDisclaimer}
                                id="disclaimer"
                                label={translate.CONDITIONS.BANNER_DISCLAIMER}
                                onChange={acceptDisclaimer}
                                type="checkbox"
                            />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <div className="mr-5 mb-3">
                        <LoadingButton
                            disabled={!areConditionsAccepted}
                            onClick={handleClose}
                            text={translate.ACCESS_SITE}
                            variant="secondary"
                            className='custom-font-weight-medium'
                        />
                    </div>
                </Modal.Footer>
            </CorneredContainer>
        </Modal>
    );
}

export default Conditions;
