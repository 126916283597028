import { useSelector } from 'react-redux';
import { selectUser } from '@/state/user';

import Loading from '@/components/loading';
import SelectLanguage from '@/components/select-language';
import useTranslate from '@/hooks/useTranslate';

function Profile() {
    const user = useSelector(selectUser);
    const translate = useTranslate();

    if (user.loading || !user.value) {
        return <Loading />;
    }

    if (user.error) {
        return <div>{user.error}</div>;
    }

    return (
        <div className="d-flex flex-column align-items-center profile">
            <div className="profile-card">
                <div className="profile-header text-center custom-font-weight-medium"><span>{user.value.Name}</span></div>
                <div className="profile-content p-5">
                    <ul>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.CATEGORY}: <span className='custom-font-weight-medium'>{user.value.Category}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.CATEGORY_NAME}: <span className='custom-font-weight-medium'>{user.value.CategoryName}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.CHAMPIONSHIPS}: <span className='custom-font-weight-medium'>{user.value.Championships}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.CONTENTTYPES}: <span className='custom-font-weight-medium'>{user.value.ContentTypes}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.EXCLUSIVE_INCLUSION_MATCHES}: <span className='custom-font-weight-medium'>{user.value.ExclusiveInclusionMatches}</span></li>
                        <li className='custom-font-weight-regular'>{translate.PROFILE_LABELS.TEAMS}: <span className='custom-font-weight-medium'>{user.value.Teams}</span></li>

                        <li><hr /></li>
                        <li className="language custom-font-weight-regular">{translate.SELECT_LANGUAGE}: <SelectLanguage /></li>
                        <li><hr /></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Profile;