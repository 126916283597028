import { configureStore } from '@reduxjs/toolkit';

import core from '@/state/core';
import listing from '@/state/listing';
import user from '@/state/user';
import search from '@/state/search';
import menu from '@/state/menu';

export default configureStore({
    reducer: {
        core,
        listing,
        user,
        search,
        menu
    }
});
