import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '@/shared/constants';
import { logout } from '@/state/user';
import useLocalStorage from '@/hooks/useLocalStorage';

function Logout() {
    const storage = useLocalStorage();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(function() {
        dispatch(logout());

        storage.removeToken();
        history.replace(ROUTES.LOGIN);
    }, [storage.storedToken]);

    return <div>Logout in progress ...</div>;
}

export default Logout;
