export enum CornerPosition {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
}

interface Props {
  position: CornerPosition
}

function ColoredCorner({position} : Props) {
  const cornerClassName: string = `colored-corner ${position}-colored-corner`;
  return (
    <div className={cornerClassName}></div>
  );
}

export default ColoredCorner;