import { doFetch } from '@/shared/apiUtils';
import { ChampionshipsValues } from '@/shared/constants';
import { ILogin, IListingResponse, IReportItem } from '@/shared/interfaces';
import { timeout } from '@/shared/utils';

export async function login({ username, password }: ILogin): Promise<string> {
    return doFetch({ path: 'auth/login', method: 'POST', body: { password, username } });
}

export async function getListing(championship: ChampionshipsValues): Promise<IListingResponse> {
    return doFetch({ path: 'calendar', useToken: true, queryString: { championship } });
}

export async function downloadLink(fileName: string, prodStd: string | undefined, prodValue: number | undefined): Promise<string> {
    return doFetch({ path: 'GetDownloadLink', queryString: { fileName, prodStd, prodValue }, useToken: true });
}

export async function purgeCache(fileName: string): Promise<string> {
    return doFetch({ path: 'PurgeCache', queryString: { fileName }, useToken: true });
}

export async function downloadStatistics(dateFrom: string, dateTo: string): Promise<IReportItem[]> {
    const arrayString = await doFetch<string>({ path: 'GetDownloadStatistics', queryString: { dateFrom, dateTo }, useToken: true });
    return JSON.parse(arrayString);
}

export async function changePassword(oldPassword: string, newPassword: string) {
    console.log('changePassword', oldPassword, newPassword);
    await timeout();
    return true;
}

export async function resetPassword(email: string) {
    console.log('resetPassword', email);
    await timeout();
    return true;
}

