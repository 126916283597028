import { createSelector, createSlice } from '@reduxjs/toolkit';
import { IMenuState, IState } from '@/shared/interfaces';

const initialState: IMenuState = { };

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuOpen: (state: IMenuState, { payload }) => {
            state = {
                ...state,
                value: payload
            };
            return state;
        }
    }
});

export const { setMenuOpen } = menuSlice.actions;

export default menuSlice.reducer;

export const selectMenu = (state: IState) => state.menu;

export const selectMenuIsOpen = createSelector(
    selectMenu,
    (state: IMenuState) => state.value?.isOpen
);