import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ISearchState, IState } from '@/shared/interfaces';

const initialState: ISearchState = { };

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        startSearch: (state: ISearchState, { payload }) => {
            state = {
                ...state,
                value: payload
            };
            return state;
        },
        resetSearch: (state) => {
            state = initialState;
            return state;
        }
    }
});

export const { startSearch, resetSearch } = searchSlice.actions;

export default searchSlice.reducer;

export const selectSearch = (state: IState) => state.search;

export const selectSearchText = createSelector(
    selectSearch,
    (state: ISearchState) => state.value?.text
);