import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { getListing } from '@/shared/api';
import { IState, IListingState, IListingResponse, IListingResponseItem, IListingGroup } from '@/shared/interfaces';
import { generateThunk } from '@/shared/utils';
import { logout } from '@/state/user';

const initialState: IListingState = { };

function convertListingResponse(listingResponse: IListingResponse): IListingGroup[] {
    const mapItems = ({ coverImage, fileName, fileSize, note, prodStd, prodValue, title }: IListingResponseItem) => ({
        fileName,
        fileSize,
        id: fileName,
        length: undefined,
        notes: note,
        prodStd: prodStd,
        prodValue: prodValue,
        thumbnail: coverImage,
        title: title || fileName
    });

    const mapRoot = (name: string) => ({ name, items: listingResponse[name]?.map(mapItems) || [] });

    return Object.keys(listingResponse).map(mapRoot);
}

export const getListingThunk =  createAsyncThunk('listing/get', (thunkAPI, store): Promise<IListingResponse> => {
    const championship = (store.getState() as IState).core.value?.championship;

    return generateThunk({ fn: getListing, thunkAPI, payload: championship });
});

export const listing = createSlice({
    name: 'listing',
    initialState,
    reducers: { },
    extraReducers: builder => {
        builder.addCase(getListingThunk.pending, (state: IListingState) => {
            state.loading = true;
            state.loaded = false;
            return state;
        });

        builder.addCase(getListingThunk.fulfilled, (state: IListingState, { payload }) => {
            state.loading = false;
            state.loaded = true;

            if (payload) {
                state.value = convertListingResponse(payload);
            }

            return state;
        });

        builder.addCase(getListingThunk.rejected, (state: IListingState, { error }) => {
            state.loading = false;
            state.loaded = false;
            state.error = error;

            return state;
        });

        builder.addCase(logout, () => initialState);
    }
});

export default listing.reducer;

export const selectListing = (state: IState) => state.listing;

export const makeGetListingItem = (id: string) => createSelector(
    selectListing,
    (state: IListingState) => state.value?.flatMap(({ items }) => items).find((item) => item.id === id)
);

export const selectListingIsLoading = createSelector(
    selectListing,
    (state: IListingState) => state?.loading
);
