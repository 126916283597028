import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from '@/app';
import reportWebVitals from '@/reportWebVitals';
import store from '@/state/store';

import '@/style/index.scss';
import '@/style/custom.scss';

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
        <CookiesProvider>
            <App />
        </CookiesProvider>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
